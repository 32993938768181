<template>
  <div class="custom-ingredients">
    <modal :show="showModal" :clickOutside="close">
      <button @click="close" class="close"><img src="@/assets/img/close.svg" alt="" /></button>
      <div class="added-ingredients" v-if="hasCustomIngredients">
        <h2>Egna tillagda ingredienser</h2>
        <ul class="ingredient-list">
          <li v-for="(ingredient, index) in customIngredients" :key="index">
            <p>{{ ingredient }}</p>
            <button @click="removeCustomIngredient(index)"><img src="@/assets/img/close-sm.svg" alt="" /></button>
          </li>
        </ul>
      </div>
      <h2>{{ hasCustomIngredients ? 'Lägg till fler ingredienser' : 'Välj en egen ingrediens' }}</h2>
      <div class="form">
        <input type="text" v-model="ingredientName" v-on:keyup.enter="addCustomIngredientToList" />
        <button @click="addCustomIngredientToList">OK</button>
      </div>
      <ul class="ingredient-list">
        <li v-for="(ingredient, index) in currentCustomIngredients" :key="index">
          <p>{{ ingredient }}</p>
          <button @click="removeCustomIngredientFromList(index)"><img src="@/assets/img/close-sm.svg" alt="" /></button>
        </li>
      </ul>
      <Button v-if="this.currentCustomIngredients.length > 0" :click="addCustomIngredients">Lägg till i blendern</Button>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Modal, Button } from '@/components'

export default {
  components: {
    Modal,
    Button,
  },
  data: () => ({
    showModal: false,
    ingredientName: '',
    currentCustomIngredients: [],
  }),
  mounted() {
    this.showModal = true
  },
  methods: {
    addCustomIngredientToList() {
      if (this.ingredientName.length > 0) {
        if (!this.currentCustomIngredients.includes(this.ingredientName) && !this.customIngredients.includes(this.ingredientName)) {
          this.currentCustomIngredients.push(this.ingredientName)
        }
        this.ingredientName = ''
      }
    },
    removeCustomIngredientFromList(index) {
      this.currentCustomIngredients.splice(index, 1)
    },
    removeCustomIngredient(index) {
      this.$store.dispatch('recipe/removeCustomIngredient', index)
    },
    addCustomIngredients() {
      this.$store.dispatch('recipe/addCustomIngredients', this.currentCustomIngredients)
      this.goToGame()
    },
    close() {
      this.showModal = false
      this.goToGame()
    },
    goToGame() {
      setTimeout(() => {
        this.$router.push({ name: 'ingredients' })
      }, 200)
    }
  },
  computed: {
    ...mapGetters({
      customIngredients: 'recipe/customIngredients',
    }),
    hasCustomIngredients() {
      return this.customIngredients.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>

h2 {
  @include rem(font-size, 26px);
  @include rem(line-height, 34px);
  @include rem(margin-bottom, $gutter);
  text-align: center;
  color: $green;
}

/deep/ .content {
  position: relative;
}

.close {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -77px;
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
}

p,
a {
  display: inline-block;
  @include rem(margin-bottom, $gutter / 2);
}

.btn {
  @include rem(margin-top, $gutter);
  margin-bottom: 0;
  width: 100%;
}

.ingredient-list {
  @include rem(margin-top, $gutter);

  li {
    @include rem(margin-bottom, 5px);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  button {
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
}

.form {
  display: flex;
  position: relative;

  button {
    @include rem(width, 64px);
    @include rem(height, 48px);
    @include rem(border-top-right-radius, 5px);
    @include rem(border-bottom-right-radius, 5px);
    background-color: $green;
    border: 0;
    padding: 0;
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    outline: 0;
    cursor: pointer;
  }

  input {
    @include rem(padding, 0 calc(64px + #{$gutter / 2}) 0 $gutter / 2);
    @include rem(height, 48px);
    width: 100%;
    border: 2px solid $green;
    outline: 0;
    border-radius: 5px;
  }
}

.added-ingredients {
  @include rem(margin-bottom, $gutter * 1);
}

@media #{$wide} {
  h2 {
    @include rem(margin-bottom, $gutter * 1);
  }

  .added-ingredients {
    @include rem(margin-bottom, $gutter * 1.5);
  }
}
</style>
